// -----------------------------------------
// Utility Mixins
//
// @author Masayuki Ietomi <jyokyoku@gmail.com>
// -----------------------------------------

$devices: (
	name: sp,
	font-size: 12px,
	max-width: 480px
),
(
	name: tb,
	font-size: 14px,
	min-width: 481px,
	max-width: 768px,
),
(
	name: pc,
	font-size: 16px,
	min-width: 769px,
) !default;

@function convert-px-values($base-font-size, $values...) {
	$px-values: ();

	@each $value in $values {
		@if type-of($value) != number {
			// not number
			$px-values: join($px-values, $value);
		} @else if $value == 0 or $value == 0px {
			// 0
			$px-values: join($px-values, 0);
		} @else if not unitless($value) and unit($value) == px {
			// px value given
			$px-values: join($px-values, round($value));
		} @else {
			// unitless value
			$px-values: join($px-values, round($value * $base-font-size));
		}
	}

	@return $px-values;
}

@function convert-rem-values($base-font-size, $values...) {
	$rem-values: ();

	@each $value in $values {
		@if type-of($value) != number {
			// not number
			$rem-values: join($rem-values, $value);
		} @else if $value == 0 or $value == 0px {
			// 0
			$rem-values: join($rem-values, 0);
		} @else if not unitless($value) and unit($value) == px {
			// px value given
			$new-rem-value: $value / $base-font-size;
			$rem-values: join($rem-values, #{$new-rem-value}rem);
		} @else {
			// unitless value
			$rem-values: join($rem-values, #{$value}rem);
		}
	}

	@return $rem-values;
}

@function get-device-value($device-name, $key) {
	@each $device in $devices {
		@if type-of($device) == map and map-get($device, 'name') == $device-name and map-get($device, $key) {
			@return map-get($device, $key);
		}
	}

	@return null;
}

@mixin clearfix() {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

@mixin placeholder-color($color) {
	&:placeholder-shown {
		color: $color;
	}

	&::-webkit-input-placeholder {
		color: $color;
	}

	&::-moz-placeholder {
		color: $color;
		opacity: 1;
	}

	&:-ms-input-placeholder {
		color: $color;
	}
}

@mixin rem-prop($property, $base-font-size, $values...) {
	//#{$property}: convert-px-values($base-font-size, $values...);
	#{$property}: convert-rem-values($base-font-size, $values...);
}

@mixin grids($column: 12, $prefix: null) {
	@if & {
		$col-width: 100 / $column;

		@if type-of($prefix) == string and $prefix {
			$prefix: "-" + $prefix;
		} @else {
			$prefix: "";
		}

		@for $i from 1 through $column {
			> #{& + $prefix}-#{$i} {
				float: left;
				width: #{$col-width * $i + "%"};
			}

			> .prefix#{$prefix}-#{$i} {
				margin-left: #{$col-width * $i + "%"};
			}

			> .suffix#{$prefix}-#{$i} {
				margin-right: #{$col-width * $i + "%"};
			}
		}
	}
}

@mixin widths($steps: 5, $prefix: null) {
	@if type-of($prefix) == "string" and $prefix != "" {
		$prefix: "-" + $prefix;
	} @else {
		$prefix: "";
	}

	$increment: ceil(100 / $steps);

	@for $i from 0 through $steps {
		.u#{$prefix}-w-#{$i * $increment}pct {
			width: #{$i * $steps + if($i * $increment == 0, '', '%')} !important;
		}
	}
}

@mixin spaces($base-font-size, $max: 100px, $steps: 5, $prefix: null) {
	$directions: top, right, bottom, left;
	$unitless-value: $max / ($max * 0 + 1);

	@if type-of($prefix) == "string" and $prefix != "" {
		$prefix: "-" + $prefix;
	} @else {
		$prefix: "";
	}

	$increment: ceil($unitless-value / $steps);

	@for $i from 0 through $steps {
		@each $direction in $directions {
			$direction_initial: str-slice($direction, 0, 1);

			.u#{$prefix}-m#{$direction_initial}-#{$i * $increment}px {
				margin-#{$direction}: convert-px-values($base-font-size, $i * $increment * 1px) !important;
				margin-#{$direction}: convert-rem-values($base-font-size, $i * $increment * 1px) !important;
			}

			.u#{$prefix}-p#{$direction_initial}-#{$i * $increment}px {
				padding-#{$direction}: convert-px-values($base-font-size, $i * $increment * 1px) !important;
				padding-#{$direction}: convert-rem-values($base-font-size, $i * $increment * 1px) !important;
			}
		}
	}
}

@mixin utils($prefix: null) {
	@if type-of($prefix) == "string" and $prefix {
		$prefix: "-" + $prefix;
	} @else {
		$prefix: "";
	}

	.u#{$prefix}-tl {
		text-align: left !important;
	}

	.u#{$prefix}-tr {
		text-align: right !important;
	}

	.u#{$prefix}-tc {
		text-align: center !important;
	}

	.u#{$prefix}-fl {
		float: left !important;
	}

	.u#{$prefix}-fr {
		float: right !important;
	}

	.u#{$prefix}-fn {
		float: none !important;
	}

	@if $prefix {
		.u#{$prefix}-hidden {
			display: none !important;
		}

		.u#{$prefix}-block {
			display: block !important;
		}

		.u#{$prefix}-inline-block {
			display: inline-block !important;
		}

		.u#{$prefix}-inline {
			display: inline !important;
		}
	}
}

@mixin device-media-query($device) {
	@if type-of($device) == map {
		$max-width: map-get($device, max-width);
		$min-width: map-get($device, min-width);

		@if map-get($device, name) {
			@if ($max-width and $min-width) {
				@media only screen and (min-width: $min-width) and (max-width: $max-width) {
					@content;
				}
			} @else if ($max-width) {
				@media only screen and (max-width: $max-width) {
					@content;
				}
			} @else if ($min-width) {
				@media only screen and (min-width: $min-width) {
					@content;
				}
			} @else {
				@content;
			}
		}
	}
}

@mixin device-content($use) {
	@each $device in $devices {
		@if type-of($device) == map and map-get($device, name) == $use {
			@include device-media-query($device) {
				@content;
			}
		}
	}
}

@mixin device-prop($property, $use, $key) {
	@each $device in $devices {
		@if type-of($device) == map and map-get($device, name) == $use and map-get($device, $key) {
			#{$property}: #{map-get($device, $key)};
		}
	}
}

@mixin device-prop-rem($property, $use, $values...) {
	@each $device in $devices {
		@if type-of($device) == map and map-get($device, name) == $use and map-get($device, font-size) {
			@include rem-prop($property, map-get($device, font-size), $values...);
		}
	}
}

@mixin map-device-prop($property, $key) {
	@each $device in $devices {
		@if type-of($device) == map and map-get($device, $key) {
			@include device-media-query($device) {
				#{$property}: #{map-get($device, $key)};
			}
		}
	}
}

@mixin map-device-grids($column: 12) {
	@if & {
		@include clearfix;

		@each $device in $devices {
			@include device-media-query($device) {
				@include grids($column, map-get($device, name))
			}
		}
	}
}

@mixin map-device-widths($steps: 10) {
	@each $device in $devices {
		@include device-media-query($device) {
			@include widths($steps, map-get($device, name))
		}
	}
}

@mixin map-device-spaces($base-font-size, $max: 100px, $steps: 10) {
	@each $device in $devices {
		@include device-media-query($device) {
			@include spaces($base-font-size, $max, $steps, map-get($device, name))
		}
	}
}

@mixin map-device-utils() {
	@each $device in $devices {
		@include device-media-query($device) {
			@include utils(map-get($device, name))
		}
	}
}