@import "config";
@import "mixin";
@import "util";

.side-blog {
	margin-top: 10px;
	border: 1px solid #a849a3;

	.side-blog__title {
		font-size: 16px;
		background: linear-gradient(to bottom, #e570e7 0%, #c85ec7 47%, #a849a3 100%);
		color: #fff;
		padding: 10px;
	}
}

.side-blog-post {
	padding: 10px;
	border-top: 1px solid #a849a3;

	img {
		max-width: 100%;
		height: auto;
	}

	a:hover {
		img {
			opacity: 0.7;
		}
	}

	.side-blog-post__title {
		font-size: 14px;
		margin: 10px 0;
	}

	.side-blog-post__desc {
		background: #fff7f4;
		padding: 10px;
		line-height: 1.4;
		font-size: 12px;
		margin-bottom: 10px;
	}

	.side-blog-post__link {
		text-align: right;
		font-size: 12px;
	}
}

.footer-blog {
	clear: both;
	display: flex;
	margin: 0 -5px 20px;
	padding-top: 20px;

	.footer-blog__col {
		width: 50%;
		padding: 0 5px;
	}

	.footer-blog__list {
		border: 1px solid #a849a3;
		padding: 10px;
	}

	.footer-blog__title {
		font-size: 16px;
		margin: -10px -10px 10px;
		float: none;
		width: auto;
		display: block;
		height: auto;
		background: linear-gradient(to bottom, #e570e7 0%, #c85ec7 47%, #a849a3 100%);
		color: #fff;
		padding: 10px;
	}

	.footer-blog__link {
		text-align: center;

		a {
			display: inline-block;
			background: linear-gradient(to bottom, #e570e7 0%, #c85ec7 47%, #a849a3 100%);
			color: #fff;
			padding: 5px 40px;
			text-decoration: none;
			border-radius: 40px;
			text-align: center;

			&:hover {
				opacity: 0.7;
			}
		}
	}
}

.footer-blog-post {
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 1px dotted #a67aa8;
	display: flex;

	&:last-child {
		border-bottom: 0;
	}

	img {
		max-width: 100%;
		height: auto;
	}

	a:hover {
		img {
			opacity: 0.7;
		}
	}

	.footer-blog-post__img {
		width: 100px;
		margin-right: 10px;
	}

	.footer-blog-post__body {
		width: 190px;
	}

	.footer-blog-post__title {
		font-size: 14px;
		margin: 0 0 8px;
	}

	.footer-blog-post__desc {
		line-height: 1.6;
		font-size: 12px;
		margin-bottom: 10px;
	}

	.footer-blog-post__link {
		text-align: right;
		font-size: 12px;
	}
}

